<template>
  <b-card :class="isCoordinationCenter ? 'mt-5' : ''">
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4>{{ $t('backoffice.settings.images') }}</h4>
      <b-button variant="primary" class="ml-1" @click="onSubmit()">
        {{ $t('form.actions.save') }}
      </b-button>
    </div>
    <b-row>
      <b-col>
        <!-- Logo -->
        <b-form-group>
          <label v-if="!currentCollective.parentKey" for="logo" class="font-weight-bold"> {{ $t('backoffice.settings.logo') }}</label>
          <label v-else for="logo" class="font-weight-bold"> {{ $t('backoffice.settings.thumbnail') }}</label>
          <feather-icon
            v-b-tooltip.hover.bottom
            :title="`${$t('tooltips.recommended')}  
            100px x 100px`"
            icon="InfoIcon"
            size="14"
            class="text-primary ml-25 mt-n25"
          />
          <br>
          <b-media-body>
            <div>
              <b-form-file
                v-model="imageLogo"
                :accept="FileTypes"
                class="pointer"
                :placeholder="$t('form-create-item.image-placeholder')"
                :browse-text="$t('form-create-item.image-button')"
              />
            </div>
          </b-media-body>
          <div class="d-block mx-auto" style="position: relative; width: 80px">
            <b-img
              :src="logoSrc || logoPlaceholder"
              width="80"
              class="rounded my-1  mb-md-0 avatar"
              style="background: #c0c0c0; min-height: 70px; cursor: pointer"
            />
            <feather-icon
              v-if="logoSrc"
              icon="XCircleIcon"
              size="18"
              class="text-primary mx-auto close-icon"
              @click="removeImg(logoSrc)"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- Banner -->
        <b-form-group>
          <label for="logo" class="font-weight-bold">
            {{ $t('backoffice.settings.banner') }}
            <feather-icon
              v-b-tooltip.hover.bottom
              :title="`${$t('tooltips.recommended')} 650px x 150px`"
              icon="InfoIcon"
              size="14"
              class="text-primary ml-25 mt-n25"
            />
          </label>
          <br>
          <b-media no-body vertical-align="bottom" class="d-flex flex-wrap align-items-center">
            <b-media-body>
              <div>
                <b-form-file
                  v-model="imageChallenge"
                  :accept="FileTypes"
                  class="pointer"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
              <div class="d-block mx-auto" style="position: relative; width: 300px">
                <b-img
                  ref="refPreviewEl"
                  :src="imageSrc || bannerPlaceholder"
                  width="300"
                  class="rounded my-1 mb-md-0"
                  style="background: #c0c0c0; min-height: 70px;"
                />
                <feather-icon
                  v-if="imageSrc"
                  icon="XCircleIcon"
                  size="18"
                  class="text-primary d-block  mx-auto close-icon"
                  @click="removeImg(imageSrc)"
                />
              </div>
            </b-media-body>
          </b-media>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import BannerPlaceholder from '@/assets/images/placeholders/light/bg_banner.png';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { required, max } from '@validations';
import FileTypes from '@/@core/constants/FileTypes';

export default {
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        logoURL: '',
        banner: '',
      },
      imageChallenge: null,
      imageLogo: null,
      imageSrc: null,
      logoSrc: null,
      required,
      max,
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    bannerUrl() {
      return getImageResource(this.currentCollective?.bgURL) || BannerPlaceholder;
    },
    logoUrl() {
      return getImageResource(this.currentCollective.logoURL) || LogoPlaceholder;
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    logoPlaceholder() {
      return LogoPlaceholder;
    },
    bannerPlaceholder() {
      return BannerPlaceholder;
    },
    mainData() {
      return this.$store.getters.mainData.unpaginated[0];
    },
    isCoordinationCenter() {
      return this.$route.params.communityId.includes('coordination-center');
    },
  },
  watch: {
    imageChallenge(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    imageLogo(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.logoSrc = value;
            })
            .catch(() => {
              this.logoSrc = null;
            });
        } else {
          this.logoSrc = null;
        }
      }
    },
  },
  async created() {
    this.imageSrc = this.bannerUrl;
    this.logoSrc = this.logoUrl;
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onSubmit() {
      const images = {
        logo_url: this.imageLogo,
        banner_url: this.imageChallenge,
        deleteLogo: this.logoSrc == null ? true : false,
        deleteBanner: this.imageSrc == null ? true : false
      };
      const headerData = {
        banner_image_background_color: this.currentCollective?.header?.banner_image_background_color,
        banner_image_background_size: this.currentCollective?.header?.banner_image_background_size,
        banner_image_height: this.currentCollective?.header?.banner_image_height,
        show_banner: this.currentCollective?.header?.show_banner,
        banner_url: this.currentCollective?.header?.banner_url,
        logo_url: this.currentCollective?.header?.logo_url,
      };
      const isBgURL = true;
      const isLogoURL = true;
      try {
        const response = await this.$store.dispatch('editCommunity', {
          headerData,
          images,
          isBgURL,
          isLogoURL,
        });
        this.notifySuccess(this.$t('backoffice.settings.messages.success'));
        return response.data;
      } catch(e){
        console.log(e)
        this.notifyError(this.$t('backoffice.settings.messages.error'));
      }
    },
    removeImg(item) {
      if (item === this.logoSrc) {
        this.imageLogo = null;
        this.logoSrc = null;
      } else {
        this.imageChallenge = null;
        this.imageSrc = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
</style>
